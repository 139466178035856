@import-normalize;

@import "./constants";
@import "./colors";

@font-face {
	font-family: "Mulish";
	font-style: normal;
	font-weight: 100 800;
	src: url("../fonts/Mulish.ttf") format("truetype-variations");
	font-display: swap;
}

@font-face {
	font-family: "Mulish";
	font-style: italic;
	font-weight: 100 800;
	font-variation-settings: "ital" 1;
	src: url("../fonts/Mulish-Italic.ttf") format("truetype-variations");
	font-display: swap;
}

@font-face {
	font-family: "Quicksand";
	font-style: normal;
	font-weight: 100 800;
	src: url("../fonts/Quicksand.ttf") format("truetype-variations");
	font-display: swap;
}

html,
body {
	scroll-behavior: smooth;
	overscroll-behavior: none;
	height: 100%;
	font-family: $font-family-base;
	width: 100%;
	max-width: 100%;
}

body {
	margin: 0;
	font-size: 14px;
	background-color: transparent;
	font-weight: 400;
}

body,
input,
button,
select,
textarea,
optgroup,
option {
	font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $color-interface-dark-deep;
}

html {
	box-sizing: border-box;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-highlight;

	em {
		background: var(
			--extra-pure,
			linear-gradient(57deg, #eb545e 0%, #f55 50%, #ff985f 100%)
		);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-style: normal;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.container {
	max-width: $max-width-xl;
	margin: 0 auto;

	@media (max-width: $max-width-md) {
		max-width: $max-width-md;
	}

	@media (max-width: $max-width-sm) {
		max-width: $max-width-sm;
	}
}

a,
a:visited,
a:hover {
	text-decoration: none;
	color: inherit;
}
