@import "static/styles/reset";
@import "static/styles/colors";
@import "static/styles/constants";

.main-header {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 2;
	background-color: $color-interface-light-pure;
	border-bottom: $border-width-hairline solid $color-interface-light-deep;

	.container {
		padding: $spacing-sm;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	&-logo {
		width: 7rem;
		height: 2rem;
	}

	&-menu {
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: center;

		@media (max-width: $max-width-md) {
			flex-direction: row;
		}

		&-download-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: $spacing-xxs;
			padding: $spacing-xxs $spacing-sm;
			border-radius: $border-radius-pill;
			background-color: $color-brand-primary-pure;
			color: $color-interface-light-pure;
			font-size: $font-size-xxs;
			line-height: $line-height-xxs;
			font-family: $font-family-highlight;
			font-weight: $font-weight-bold;
			cursor: pointer;

			&:hover {
				background-color: $color-brand-primary-deep;
			}

			@media (max-width: $max-width-sm) {
				display: none;
			}

			img {
				width: 1rem;
				height: 1rem;
			}
		}

		&-burger {
			@media (max-width: $max-width-md) {
				width: 1.5rem;
				height: 1.5rem;
				margin-left: $spacing-xl;
			}

			label {
				cursor: pointer;
				display: none;
				padding: 0.3125rem 0.1875rem;

				@media (max-width: $max-width-md) {
					display: block;
				}

				span {
					display: block;
					width: 1.125rem;
					height: 0.125rem;
					position: relative;
					background: $color-brand-primary-pure;
					border-radius: 3px;
					z-index: 1;
					transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
						background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
						opacity 0.55s ease;

					&:nth-child(1) {
						transform-origin: 0% 0%;
					}

					&:nth-child(3) {
						transform-origin: 0% 100%;
					}

					& + span {
						margin-top: 0.25rem;
					}
				}
			}

			input {
				cursor: pointer;
				opacity: 0;
				position: absolute;
				outline: none;
				display: none;

				@media (max-width: $max-width-md) {
					display: block;
				}

				&:checked ~ ul {
					visibility: visible;
					opacity: 1;
					transform: translateX(0%);
					width: 100%;
					overflow: auto;
				}

				&:checked ~ label span {
					&:nth-child(1) {
						opacity: 1;
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						opacity: 0;
						transform: rotate(0deg) scale(0.2, 0.2);
					}

					&:nth-child(3) {
						opacity: 1;
						transform: rotate(-45deg);
					}
				}
			}

			ul {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: $spacing-xl;
				font-family: $font-family-highlight;
				font-weight: $font-weight-bold;
				font-size: $font-size-xs;
				line-height: $line-height-xs;
				transition: all 200ms ease-in-out;

				li {
					.active {
						color: $color-brand-primary-pure;
					}

					&:hover {
						color: $color-brand-primary-pure;

						.active {
							color: $color-brand-primary-pure;
						}
					}
				}

				@media (max-width: $max-width-md) {
					flex-direction: column;
					background-color: $color-interface-light-pure;
					position: absolute;
					right: 0;
					top: 4rem;
					gap: 0;
					width: 0;
					transform: translateX(100%);
					opacity: 0;
					overflow: hidden;

					li {
						width: 100%;
						flex: 1;
						border-bottom: $border-width-hairline solid
							$color-interface-light-deep;

						&:first-child {
							border-top: $border-width-hairline solid
								$color-interface-light-deep;
						}

						a {
							display: block;
							padding: $spacing-xs $spacing-sm;
							text-align: left;
						}
					}
				}
			}
		}

		&-download-link {
			display: none;

			@media (max-width: $max-width-sm) {
				display: block;
			}
		}
	}
}

$headerSize: 68px;

.main-section {
	margin-top: $headerSize;
}

.main-footer {
	&-downloads {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: $spacing-xxxl 0;

		img {
			height: 2.5rem;
			width: 14.5rem;
		}

		p {
			margin-top: $spacing-md;
			color: $color-interface-dark-up;
			font-size: $font-size-sm;
			font-weight: $font-weight-semi-bold;
			line-height: $line-height-sm;
		}
	}

	&-testimonials {
		display: flex;
		align-items: center;
		width: 100%;
		overflow: hidden;

		$cardsSize: 20rem;
		$cardsNumber: 16;

		.container2 {
			position: relative;
			width: calc($cardsSize * $cardsNumber);
			height: 25rem;
			padding: 2rem 0;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				display: none;
			}

			.content {
				width: inherit;
				height: inherit;
				position: absolute;
				left: 0%;
				top: 0%;
				display: flex;
				align-items: center;
				justify-content: space-around;

				blockquote {
					width: $cardsSize;
					flex: 1;
					min-height: 25rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin: 0;
					padding: $spacing-xl $spacing-lg;
					border-top: $border-width-thick solid
						$color-interface-light-deep;

					&:nth-child(14),
					&:nth-child(10),
					&:nth-child(6),
					&:nth-child(2) {
						border-color: $color-feedback-warning-down;
						background: $color-feedback-warning-up;

						footer .testimonial-author-picture {
							background-color: $color-feedback-warning-down;

							svg path {
								stroke: $color-feedback-warning-deep;
							}
						}
					}

					&:nth-child(16),
					&:nth-child(12),
					&:nth-child(8),
					&:nth-child(4) {
						border-color: $color-brand-primary-down;
						background: $color-brand-primary-up;

						footer .testimonial-author-picture {
							background-color: $color-brand-primary-down;
						}

						svg path {
							stroke: $color-brand-primary-deep;
						}
					}

					p {
						color: $color-interface-dark-deep;
						font-size: $font-size-sm;
						font-weight: $font-weight-medium;
						line-height: $line-height-md;
					}

					footer {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: $spacing-sm;

						.testimonial-author-picture {
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 99999px;
							padding: $spacing-xs;
							background-color: $color-interface-light-deep;
						}

						.testimonial-author {
							display: flex;
							flex-direction: column;
							font-family: $font-family-highlight;
							font-size: $font-size-xs;
							font-weight: $font-weight-bold;
							line-height: $line-height-xs;

							svg {
								width: 7.25rem;
								height: 1.25rem;
							}
						}
					}
				}
			}
		}
	}

	&-download {
		background-color: $color-interface-dark-pure;
		padding: $spacing-xxxl $spacing-md;

		@media (max-width: $max-width-md) {
			padding-top: $spacing-md;
			padding-bottom: $spacing-xl;
		}

		h2 {
			color: $color-interface-light-pure;
			text-align: center;
			font-size: $font-size-xl;
			font-weight: $font-weight-bold;
			line-height: $line-height-xl;
			margin-bottom: $spacing-xl;

			@media (max-width: $max-width-md) {
				font-size: $font-size-sm;
				line-height: $line-height-sm;
				margin-bottom: $spacing-md;
			}
		}

		ul {
			display: flex;
			flex-direction: row;
			gap: $spacing-md;
			align-items: center;
			justify-content: center;

			@media (max-width: $max-width-md) {
				gap: $spacing-xs;
			}

			li {
				img,
				a {
					display: block;
				}

				&:nth-child(1) img {
					width: 13.5625rem;
					height: 4rem;
				}

				&:nth-child(2) img {
					width: 12rem;
					height: 4rem;
				}

				@media (max-width: $max-width-md) {
					&:nth-child(1) img {
						width: 10.171875rem;
						height: 3rem;
					}

					&:nth-child(2) img {
						width: 9rem;
						height: 3rem;
					}
				}
			}
		}
	}

	&-links {
		.container {
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			padding: $spacing-xxxl $spacing-md;
			gap: $spacing-md;

			@media (max-width: $max-width-md) {
				flex-direction: column;
				gap: $spacing-xl;
			}
		}

		h4 {
			font-size: $font-size-xs;
			font-weight: $font-weight-bold;
			line-height: $line-height-xs;
			margin-bottom: $spacing-sm;
		}

		&-address {
			img {
				width: 7rem;
				height: 2rem;
				margin-bottom: $spacing-md;
			}

			h4 {
				margin-bottom: $spacing-xxs;
			}

			p {
				color: $color-interface-dark-deep;
				font-size: $font-size-xxs;
				font-weight: $font-weight-medium;
				line-height: $line-height-xxs;
			}
		}

		&-transparency {
			ul {
				display: flex;
				flex-direction: column;
				gap: $spacing-xxs;
			}
		}

		&-emails {
			a {
				text-decoration: underline;
			}

			a + h4 {
				margin-top: $spacing-md;
			}
		}

		&-socials {
			ul {
				display: flex;
				flex-direction: row;
				gap: $spacing-md;
			}
		}
	}
}

.primary {
	animation: primary 40s linear 1s infinite;
}

.secondary {
	animation: secondary 40s linear 1s infinite;
}

@keyframes primary {
	from {
		left: 0rem;
	}
	to {
		left: -160rem;
	}
}

@keyframes secondary {
	from {
		left: 160rem;
	}
	to {
		left: 0rem;
	}
}
