@import "static/styles/reset";
@import "static/styles/colors";
@import "static/styles/constants";

$headerSize: 68px;

.home-hero {
	.container {
		display: flex;
		flex-direction: row;
		gap: $spacing-xxxl;

		@media (max-width: $max-width-md) {
			flex-direction: column;
			gap: 0;
		}

		> section {
			width: 29rem;
			padding-left: $spacing-sm;
			display: flex;
			flex-direction: column;
			justify-content: center;
			gap: $spacing-md;

			@media (max-width: $max-width-md) {
				width: auto;
				padding: $spacing-md;
			}

			h1 {
				font-size: $font-size-display;
				font-weight: $font-weight-bold;
				line-height: $line-height-display;

				@media (max-width: $max-width-md) {
					font-size: $font-size-xxl;
					line-height: $line-height-xxl;
				}
			}

			p {
				color: $color-interface-dark-deep;
				font-size: 1.125rem;
				font-weight: $font-weight-medium;
				line-height: $line-height-xs;
			}

			a {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: $spacing-xxs;
				padding: $spacing-sm $spacing-md;
				border-radius: $border-radius-pill;
				background-color: $color-brand-primary-pure;
				color: $color-interface-light-pure;
				font-size: $font-size-xs;
				line-height: $line-height-xs;
				font-family: $font-family-highlight;
				font-weight: $font-weight-bold;
				align-self: flex-start;
				z-index: 1;

				img {
					width: 1.5rem;
					height: 1.5rem;
				}

				&:hover {
					background-color: $color-brand-primary-deep;
				}
			}
		}

		> figure {
			flex: 1;
			margin: 0;
			overflow: hidden;
			border-bottom-left-radius: 28rem;
			z-index: 0;

			@media (max-width: $max-width-md) {
				border-radius: 0;
				margin-top: -3rem;
			}

			img {
				margin-top: -10rem;
				margin-left: -10rem;
				object-fit: cover;
				object-position: center;

				@media (max-width: $max-width-md) {
					margin: 0;
					width: 100%;
					height: 32rem;
				}
			}
		}
	}
}

.home-screens {
	@media (max-width: $max-width-md) {
		margin-top: -2.25rem;
		z-index: 1;
		position: relative;
	}

	&-icons {
		display: flex;
		flex-direction: row;
		gap: $spacing-sm;
		align-items: center;
		justify-content: center;
		margin-top: 8rem;
		margin-bottom: $spacing-xl;

		@media (max-width: $max-width-md) {
			margin-top: 0;
		}

		li {
			width: 4.5rem;
			height: 4.5rem;
			border-radius: $border-radius-pill;
			background: $color-brand-primary-up;
			display: flex;
			align-items: center;
			justify-content: center;

			@media (max-width: $max-width-md) {
				&:nth-child(6) {
					display: none;
				}
			}

			@media (max-width: $max-width-sm) {
				&:nth-child(1),
				&:nth-child(5),
				&:nth-child(6) {
					display: none;
				}
			}
		}
	}

	.container {
		padding: 0 $spacing-md;

		h1 {
			margin-bottom: $spacing-xl;
			color: $color-interface-dark-deep;
			text-align: center;
			// font-size: $font-size-xxxl;
			font-size: 2.7rem; 
			font-weight: $font-weight-bold;
			line-height: $line-height-xxxl;
		}

		p {
			text-align: center;
			color: $color-interface-dark-deep;
			font-size: 1.125rem;
			font-weight: $font-weight-medium;
			line-height: $line-height-xs;
			margin-bottom: $spacing-xl;
		}
	}

	&-row {
		background: $color-brand-primary-down;
		height: 50rem;
		margin: $spacing-xxxl 0;
		padding: 0 $spacing-sm;
		background: linear-gradient(
			0deg,
			transparent 0%,
			transparent 10%,
			$color-brand-primary-down 10%,
			$color-brand-primary-down 80%,
			transparent 80%,
			transparent 0%
		);
		display: flex;
		flex-direction: row;
		gap: $spacing-xxl;
		justify-content: center;
		overflow-x: scroll;
		scrollbar-width: none;

		img {
			height: 100%;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		@media (max-width: $max-width-lg) {
			justify-content: flex-start;
		}
	}

	&-features {
		.tables {
			overflow: scroll;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.container {
			padding: 0 $spacing-md;

			@media (max-width: $max-width-md) {
				padding: 0;
			}

			h1 {
				margin-bottom: $spacing-xxxl;
				color: $color-interface-dark-deep;
				text-align: center;
				font-size: $font-size-xxxl;
				font-weight: $font-weight-bold;
				line-height: $line-height-xxxl;

				@media (max-width: $max-width-md) {
					font-size: 2.5rem;
				}
			}
		}

		table {
			width: 100%;
			border: $border-width-hairline solid $color-interface-light-deep;
			border-radius: $border-radius-xxl;
			border-spacing: 0;
			overflow: hidden;

			@media (max-width: $max-width-md) {
				overflow: scroll;
				border-radius: 0;
			}

			& + table {
				margin-top: $spacing-xxxl;
			}

			thead {
				&:first-child {
					th {
						border-top-left-radius: 2rem;
					}
				}

				h2 {
					font-size: $font-size-lg;
					line-height: $line-height-lg;
				}

				th {
					padding: $spacing-xs;

					color: $color-interface-dark-deep;
					text-align: center;
					font-family: $font-family-highlight;
					font-size: $font-size-xs;
					font-weight: $font-weight-bold;
					line-height: $line-height-xs;

					&:nth-child(1) {
						text-align: left;
						padding: $spacing-sm $spacing-md;
					}

					&:not(:nth-child(1)) {
						width: 10rem;
						min-width: 10rem;
					}

					@media (max-width: $max-width-md) {
						position: -webkit-sticky; /* for Safari */
						position: sticky;
						left: 0;
					}
				}
			}

			tbody {
				tr {
					&:nth-child(odd) {
						background-color: $color-interface-light-deep;

						th {
							background-color: $color-interface-light-deep;
						}
					}
				}

				th {
					@media (max-width: $max-width-md) {
						position: -webkit-sticky; /* for Safari */
						position: sticky;
						left: 0;
					}

					text-align: left;
					color: $color-interface-dark-deep;
					font-size: $font-size-xs;
					font-weight: $font-weight-semi-bold;
					line-height: $line-height-xs;
				}

				td,
				th {
					padding: $spacing-md;
				}

				td {
					text-align: center;
				}
			}

			tbody th:first-child,
			thead th:first-child {
				left: 0;
				z-index: 1;
				background-color: $color-interface-light-pure;
			}
		}
	}
}
